import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./AppForm.css";
import {
  removeToken,
  setToken,
  setUserDetails,
} from "../Redux/Slices/userSlice";
import { useDispatch } from "react-redux";
import { InsertDriveFileOutlined } from "@mui/icons-material";
import SignatureCanvas from "react-signature-canvas";
import generateSignatureImage from "../utils/generateSignatureImage";
import Swal from "sweetalert2";
import axios from "../axios/axios";
import TermsAndConditionContent from "./TermsAndConditionContent";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../Config/config";

import StepZero from "./FormContent/StepZero";
import StepOne from "./FormContent/StepOne";
import StepThree from "./FormContent/StepThree";
import StepFive from "./FormContent/StepFive";

import CompanyBoi from "./FormContent/BeneficialForm/CompanyBoi";
import BeneficialOwnerComponent from "./FormContent/BeneficialForm/BeneficialOwnerComponent";
import CompanyApplicantBoi from "./FormContent/BeneficialForm/CompanyApplicantBoi/CompanyApplicantBoi";
import { leadCapture } from "../services/UserService/userService";
import {
  addBeneficialsApplicants,
  callApi,
  fileChangeApplicant,
  updateForm,
} from "../services/FormService/formService";
import UserDataHandler, {
  getUserInfo,
} from "../services/GetUserData/UserDataHandler";

import { ToggleContext } from "../ToggleContext";
import { useContext } from "react";

const Form = () => {
  const styles = () => ({
    container: css`
      margin: 0 auto;
      height: 300px;
    `,
    sigCanvas: css`
      width: 100%;
      height: 100%;
    `,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [shouldReload, setShouldReload] = useState(false);
  const [shouldReloadSingleFile, setShouldReloadSingleFile] = useState(false);
  const [loader, setLoader] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const sigRef = useRef();
  const [signature, setSignature] = useState(null);
  const [signatureName, setSignatureName] = useState("");
  const { toggle } = useContext(ToggleContext);

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };
  const handleClearCanvasSignature = (e) => {
    e.preventDefault();
    sigRef.current.clear();
    setSignature(null);
  };
  const [selectedOption, setSelectedOption] = useState("autoGenerated");
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleNameChange = (event) => {
    setSignatureName(event.target.value);
  };

  useEffect(() => {
    setSignature(null);
  }, [selectedOption]);

  useEffect(() => {
    if (signatureName?.length > 0) {
      generateSignature();
    } else {
      setSignature(null);
    }
  }, [signatureName]);

  const generateSignature = async () => {
    try {
      const image = await generateSignatureImage(
        signatureName,
        "Dancing Script"
      );
      setSignature(image);
    } catch (error) {
      console.error("Error generating signature:", error);
    }
  };

  function AutoGeneratedUI() {
    return (
      <div className="mt-4">
        <span style={{ fontSize: "22px", fontWeight: "600" }}>
          {toggle ? "Firmas Autorizadas" : "Authorized Signatures"}
        </span>
        <div
          style={{
            position: "relative",
            border: "2px solid rgba(13, 6, 6, 0.18)",
            width: "100%",
            height: "170px",
            marginTop: "4px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {signature ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={signature} alt="Generated Signature" />
            </div>
          ) : (
            <>
              {toggle
                ? "Utilice el campo de nombre arriba para crear una firma."
                : "Use the name field above to create a signature."}
            </>
          )}
        </div>
      </div>
    );
  }

  function UploadPhotoUI() {
    return <div>Upload Photo UI</div>;
  }

  //code for documents beneficial owners

  const initialApplicant = {
    name: "",
    dob: "",
    address: "",
    passportNum: "",
    driver_licence_front: "",
    driver_licence_back: "",
    securityKey: "",
    blurredValue: "",
    // security_card_front: "",
    // security_card_back: "",
  };

  const [applicants, setApplicants] = useState([initialApplicant]);
  const [ssn, setSSN] = useState("");
  const [blurredValueSsn, setBlureedValue] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [addingApplicant, setAddingApplicant] = useState(false);

  const [currentSecurityIndex, setCurrentSecurityIndex] = useState("");

  const validateApplicants = () => {
    for (let i = 0; i < applicants?.length; i++) {
      const applicant = applicants[i];

      if (
        !applicant.name ||
        !applicant.dob ||
        applicant.securityKey?.length <= 0 ||
        !applicant.passportNum ||
        !applicant.driver_licence_front ||
        !applicant.driver_licence_back
        // !applicant.security_card_front ||
        // !applicant.security_card_back
      ) {
        setErrorMessage(`You Must Add All Beneficial Owners information`);
        Swal.fire({
          title: "You Must Add All Beneficial Owners information",
          icon: "error",
          customClass: {
            popup: "custom-popup",
          },
          showClass: {
            popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
          },
          hideClass: {
            popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
          },
        });
        document.getElementById(`name${i}`).classList.add("border-danger");
        document.getElementById(`dob${i}`).classList.add("border-danger");
        document
          .getElementById(`securityKey${i}`)
          .classList.add("border-danger");
        document
          .getElementById(`passportNum${i}`)
          .classList.add("border-danger");
        return false;
      } else {
        // Remove border-danger class if validation succeeds
        document.getElementById(`name${i}`).classList.remove("border-danger");
        document.getElementById(`dob${i}`).classList.remove("border-danger");
        document
          .getElementById(`securityKey${i}`)
          .classList.remove("border-danger");
        document
          .getElementById(`passportNum${i}`)
          .classList.remove("border-danger");
      }
    }
    setErrorMessage("");
    return true;
  };

  const handleFileChange = (index, field, file) => {
    console.log(file, "Fileeeeeeeeeeeeeeeeeeeeeeeee");
    console.log(index, "index");
    console.log(field, "fieldddddddddd");
    const newApplicants = [...applicants];
    newApplicants[index][field] = file;
    setApplicants(newApplicants);
  };

  const handleNameChangeBeneficialOwners = (index, fieldName, e) => {
    const newApplicants = [...applicants];
    newApplicants[index][fieldName] = e.target.value;
    setApplicants(newApplicants);
  };
  const handleSSNChangeBeneficialOwners = (index, e) => {
    const inputSSN = e.target.value;
    const lastFourDigits = inputSSN.slice(-4);
    const hiddenPart = "x".repeat(Math.max(0, inputSSN?.length - 4));
    const formattedSSN = hiddenPart + lastFourDigits;

    const newApplicants = [...applicants];
    newApplicants[index]["securityKey"] = formattedSSN;
    setApplicants(newApplicants);
  };

  const handleChangeSSN = (index, e) => {
    const inputValue = e.target.value;
    if (inputValue?.length == 9) {
      setCurrentSecurityIndex(index);
      // alert(currentSecurityIndex)
    }

    // Check if the input length exceeds 9 characters
    if (inputValue?.length > 9) {
      return;
    }
    // If the input length becomes less than the previous length of 9, clear the security key
    if (
      inputValue?.length <= 9 &&
      inputValue?.length < applicants[index]["securityKey"]?.length
    ) {
      setCurrentSecurityIndex("");
      const newApplicants = [...applicants];
      newApplicants[index]["securityKey"] = "";
      setApplicants(newApplicants);
      return;
    }

    const newApplicants = [...applicants];
    newApplicants[index]["securityKey"] = inputValue;
    newApplicants[index]["blurredValue"] = e.target.value;
    setApplicants(newApplicants);
  };
  const handleBlur = (index) => {
    const newApplicants = [...applicants];
    const inputValue = newApplicants[index]["securityKey"];

    // Mask the input value if it's 9 characters long
    if (inputValue?.length === 9) {
      const maskedValue = inputValue
        .slice(-4)
        .padStart(inputValue?.length, "*");
      newApplicants[index]["securityKey"] = maskedValue;

      // // Update blurred value in state
      // newApplicants[index]['blurredValue'] = inputValue;
      setApplicants(newApplicants);
    }
  };

  useEffect(() => {
    // Call handleBlur function and console log the blurred value
    applicants.forEach((applicant, index) => {
      if (index == currentSecurityIndex) {
        if (applicant.securityKey?.length === 9) {
          console.log("in change");
          handleBlur(index);
        }
      }
    });
  }, [currentSecurityIndex]);

  //SSN Single

  // Function to update the input value and mask it
  const handleChange = (event) => {
    // Check if the input length exceeds 9 characters
    if (event.target.value?.length > 9) {
      return null;
    }
    // Check if the input value is shorter than the previous value
    if (event.target.value?.length < ssn?.length && ssn?.length >= 9) {
      setSSN("");
      return;
    }
    setSSN(event.target.value);
    setBlureedValue(event.target.value);
  };

  useEffect(() => {
    if (ssn?.length == 9) {
      handlBlur();
    }
  }, [ssn]);

  const handlBlur = (e) => {
    const value = ssn;
    const maskedValue = value.slice(-4).padStart(value?.length, "*"); // Mask all but the last four characters
    setSSN(maskedValue);
  };

  const handleViewClick = async (file, e) => {
    e.preventDefault();
    const data = { email: userData?.email, fileName: file };
    try {
      const responseData = await callApi(data);
      console.log(responseData);
      if (responseData) {
        const viewUrl = responseData.url;
        window.open(viewUrl, "_blank");
      }
      // Reset loading state here, if needed
      // setLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const getFileNameFromUrl = (url) => {
    const segments = url.split("/");
    return segments[segments?.length - 1];
  };

  const handleViewSingleFile = async (file, e) => {
    e.preventDefault();
    if (typeof file === "string") {
      const fileName = getFileNameFromUrl(file);
      const data = { email: userData?.email, fileName: fileName };
      try {
        const responseData = await callApi(data);
        console.log(responseData);
        if (responseData) {
          const viewUrl = responseData.url;
          window.open(viewUrl, "_blank");
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    } else {
      console.error("Invalid file URL");
    }
  };

  const renderFileInputOrURL = (index, field, value, fileName) => {
    if (value && fileName) {
      // If both value (file URL) and fileName exist, render the view and remove buttons
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "320px",
            marginTop: 5,
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <InsertDriveFileOutlined sx={{ color: "red" }} />
            <span
              style={{
                fontWeight: 500,
                marginLeft: "1px",
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {fileName?.length > 10
                ? fileName.substring(0, 15) + "..."
                : fileName}
            </span>
          </div>
          <div style={{ marginLeft: "auto", display: "flex" }}>
            {/* <div style={{  display: 'flex' }}> */}
            <button
              className="btnFile"
              style={{ marginRight: "10px" }}
              onClick={(e) => handleViewClick(fileName, e)}
            >
              View
            </button>
            {/* <button className='btnFile' style={{ marginLeft: '5px' }}>Remove</button> */}
          </div>
        </div>
      );
    } else {
      // If either value or fileName is missing, render the file input
      return (
        <div style={{ width: "320px", marginTop: 5 }}>
          <input
            type="file"
            onChange={(e) => handleFileChange(index, field, e.target.files[0])}
            style={{
              border: "4px dashed rgb(153 195 227)",
              borderRadius: "5px",
              textAlign: "center",
              padding: "10px",
              width: "100%",
              marginTop: "10px",
              boxSizing: "border-box",
            }}
          />
        </div>
      );
    }
  };

  const addApplicantToDatabaseSubmission = async (newApplicant, status) => {
    try {
      if (status === "uploaded") {
        setLoader(true); // Set loading to true before making the API call
      }
      const responseData = await addBeneficialsApplicants(newApplicant);
      console.log("Response data form Update:", responseData);
      if (responseData && status === "uploaded") {
        await formUpdate();
      }
      return responseData; // Indicate success
    } catch (error) {
      console.error("Error in lead capture:", error);
    } finally {
      if (status === "uploaded") {
        setLoader(false); // Set loading to true before making the API call
      }
    }
  };

  const handleAddMore = async () => {
    // setApplicants([...applicants, initialApplicant]);
    // console.log(applicants)
    if (!validateApplicants()) {
      return;
    }

    setErrorMessage("");
    setAddingApplicant(true);
    setApplicants([...applicants, initialApplicant]);
    let result = await addApplicantToDatabaseSubmission(applicants); // Send current state to database
    if (result) {
      setShouldReload(!shouldReload);
    }
  };

  const handleCancel = () => {
    setAddingApplicant(false);
    setApplicants(applicants.slice(0, applicants?.length - 1));
  };

  const initialCompanyApplicants = {
    applicant_driver_licence_front: "",
    applicant_driver_licence_back: "",
    // applicant_security_card_front: "",
    // applicant_security_card_back: "",
  };

  const [companyApplicants, setCompanyApplicants] = useState(
    initialCompanyApplicants
  );
  const [errorApplicantMessage, setErrorApplicantMessage] = useState("");

  const handleFileChangeApplicant = async (field, file) => {
    try {
      setFileLoader(true);
      const responseData = await fileChangeApplicant(field, file);
      console.log("Response data form Update:", responseData);
      if (responseData) {
        setShouldReloadSingleFile(!shouldReloadSingleFile);
      }
    } catch (error) {
      console.error("Error in lead capture:", error);
    } finally {
      setFileLoader(false);
    }
  };

  const validateCompanyApplicant = () => {
    const {
      applicant_driver_licence_front,
      applicant_driver_licence_back,
      // applicant_security_card_front,
      // applicant_security_card_back,
    } = companyApplicants;

    if (
      !applicant_driver_licence_front ||
      !applicant_driver_licence_back
      // !applicant_security_card_front ||
      // !applicant_security_card_back
    ) {
      setErrorApplicantMessage(
        "Please select all relevant company applicant files and complete the information."
      );
      Swal.fire({
        title:
          "Please select all relevant company applicant files and complete the information.",
        icon: "error",
        customClass: {
          popup: "custom-popup",
        },
        showClass: {
          popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `,
        },
        hideClass: {
          popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `,
        },
      });
      return false;
    }
    setErrorApplicantMessage("");
    return true;
  };

  const steps = [
    "LeadCapture",
    "Conditions",
    "Aggrement",
    "Payment",
    "Confirmation",
    "ThankYou",
  ];

  const initialFormData = {
    //step lead-capture
    name: "",
    email: "",
    phone: "",
    businessName: "",
    businessAddress: "",
    isCheckedLeadCapture: "",
    isCompanyFormed: "",
    isNoMoreBeneficials: "",

    //signature

    signature_company_name: "",
    signature_title: "",
    signature_email: "",
    confirmationAggrement: "",

    //Confirmation-step

    // COMPANY BOI
    company_name: "",
    trade_name: "",
    business_StAddress: "",
    state_registration: "",
    date_of_formation: "",
    taxpayer_idNum: "",
    isCheckedConfirmation: false,

    // COMPANY APPLICANT’S BOI
    applicant_name: "",
    applicant_dob: "",
    applicant_address: "",
    applicant_passportNum: "",

    // Add other form fields here
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateInputs = () => {
    let hasErrors = false;
    const errorsObj = {};
    if (activeStep === 0) {
      if (formData.name.trim() === "") {
        errorsObj.name = "First name cannot be empty";
        hasErrors = true;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formData.email.trim() === "" || !emailRegex.test(formData.email)) {
        errorsObj.email = "Enter your valid email";
        hasErrors = true;
      }

      if (formData.phone.trim() === "") {
        errorsObj.phone = "Phone number cannot be empty";
        hasErrors = true;
      }

      if (formData.businessName.trim() === "") {
        errorsObj.businessName = "Bussiness name cannot be empty";
        hasErrors = true;
      }

      if (formData.businessAddress.trim() === "") {
        errorsObj.businessAddress = "Business address cannot be empty";
        hasErrors = true;
      }
      if (!formData.isCheckedLeadCapture) {
        errorsObj.isCheckedLeadCapture = "Please check the term & conditions";
        hasErrors = true;
      }
      if (!formData.isCompanyFormed) {
        errorsObj.isCompanyFormed = "Please check the box";
        hasErrors = true;
      }
      if (!formData.isNoMoreBeneficials) {
        errorsObj.isNoMoreBeneficials = "Please check the box";
        hasErrors = true;
      }
    }
    if (activeStep === 2) {
      if (!signature) {
        hasErrors = true;
        Swal.fire({
          title: "Please Sign the Agreement",
          icon: "error",
          showClass: {
            popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
          },
          hideClass: {
            popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
          },
        });
      }
      if (formData.signature_title.trim() === "") {
        errorsObj.signature_title = "Title is required";
        hasErrors = true;
      }
      if (formData.signature_company_name.trim() === "") {
        errorsObj.signature_company_name = "Company Name is required";
        hasErrors = true;
      }
      if (formData.signature_email.trim() === "") {
        errorsObj.signature_email = "Legal Full is required";
        hasErrors = true;
      }

      if (signatureName.trim() == "") {
        errorsObj.signatureName = "Company Name is required";
        hasErrors = true;
      }

      if (!formData.confirmationAggrement) {
        errorsObj.confirmationAggrement = "Please check the term & conditions";
        hasErrors = true;
      }
    }
    if (activeStep === 4) {
      if (formData.company_name.trim() === "") {
        errorsObj.company_name = "Company name cannot be empty";
        hasErrors = true;
      }
      if (formData.trade_name.trim() === "") {
        errorsObj.trade_name = "Trade name cannot be empty";
        hasErrors = true;
      }
      if (formData.business_StAddress.trim() === "") {
        errorsObj.business_StAddress = "Business address cannot be empty";
        hasErrors = true;
      }
      if (formData.state_registration.trim() === "") {
        errorsObj.state_registration = "State registration cannot be empty";
        hasErrors = true;
      }
      if (!formData.date_of_formation) {
        errorsObj.date_of_formation = "Date of formation cannot be empty";
        hasErrors = true;
      }
      if (formData.taxpayer_idNum.trim() === "") {
        errorsObj.taxpayer_idNum = "Taxplayer identity number cannot be empty";
        hasErrors = true;
      }

      if (formData.applicant_name.trim() === "") {
        errorsObj.applicant_name = "Applicant name cannot be empty";
        hasErrors = true;
      }
      if (!formData.applicant_dob) {
        errorsObj.applicant_dob = "Applicant date of birth cannot be empty";
        hasErrors = true;
      }
      if (formData.applicant_address.trim() === "") {
        errorsObj.applicant_address = "Applicant address cannot be empty";
        hasErrors = true;
      }
      if (formData.applicant_passportNum.trim() === "") {
        errorsObj.applicant_passportNum = "Passport number cannot be empty";
        hasErrors = true;
      }
      if (ssn?.length <= 0) {
        errorsObj.ssn = "Social security number should not be empty";
        hasErrors = true;
      }
      if (!formData.isCheckedConfirmation) {
        errorsObj.isCheckedConfirmation = "Please check the term & conditions";
        hasErrors = true;
      }
      if (!validateApplicants()) {
        hasErrors = true;
      }
      if (!validateCompanyApplicant()) {
        hasErrors = true;
      }
    }

    setErrors(errorsObj);
    return !hasErrors;
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    let inputValue;

    if (type === "checkbox") {
      inputValue = checked;
    } else if (type === "date") {
      if (value === "") {
        inputValue = ""; // Set to an empty string for no date selected
      } else {
        const dateValue = new Date(value);
        const formattedDate = dateValue.toISOString().substr(0, 10);
        inputValue = formattedDate;
      }
    } else {
      inputValue = value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: inputValue,
    }));
  };

  // Here are all api's services calls in future we will move to services for code optimization

  const handleLeadCapture = async (step) => {
    try {
      // setLoading(true); // Set loading to true before making the API call
      const responseData = await leadCapture(step, formData, dispatch);
      console.log("Response data Lead Capture:", responseData);
      if (responseData) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      console.error("Error in lead capture:", error);
    } finally {
      // setLoading(false);
    }
  };

  const formDataUpdate = async () => {
    let payload = {};

    if (
      selectedOption === "autoGenerated" ||
      selectedOption === "manualSignature"
    ) {
      payload = {
        type: selectedOption === "autoGenerated" ? "auto" : "manual",
        link: signature,
        signature_company_name: formData.signature_company_name,
        signature_title: formData.signature_title,
        signature_email: formData.signature_email,
        signature_print_name: signatureName,
      };
    }

    const token = localStorage.getItem("token");

    try {
      setLoader(true);
      const response = await axios.put("user/signatureapi", payload);

      if (response.status === 200) {
        const data = response.data;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        // Handle error
        console.error("Error in API call");
      }
    } catch (error) {
      // Handle network error
      console.error("Network error", error);
    } finally {
      // Reset loading to false after the API call is completed or errored
      setLoader(false);
    }
  };

  const formUpdate = async () => {
    try {
      setLoader(true); // Set loading to true before making the API call
      let token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing");
        setActiveStep(0);
        return;
      }
      const responseData = await updateForm(
        activeStep,
        formData,
        blurredValueSsn
      );
      console.log("Response data form Update:", responseData);
      if (responseData) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      console.error("Error in lead capture:", error);
    } finally {
      setLoader(false);
    }
  };

  const formSubmission = () => {
    const lastApplicant = applicants[applicants?.length - 1];

    const matchingBeneficialOwner = userData?.beneficial_owners.filter(
      (owner) => owner.name === lastApplicant.name
    );
    if (matchingBeneficialOwner?.length === 0) {
      addApplicantToDatabaseSubmission(applicants, "uploaded");

      // window.location.href = "https://calendly.com/bill-williamhorourke";
    } else {
      formUpdate();
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        setLoader(true);

        const response = await axios.get("user/getUser");
        if (response.status === 200) {
          const userData = response.data;
          if (userData) {
            await getUserInfo({
              userData,
              setUserData,
              setActiveStep,
              setSSN,
              setFormData,
              setApplicants,
              setCompanyApplicants,
              shouldReloadSingleFile,
            });
          }
        } else {
          console.error("Non-200 status while fetching user data");
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      } finally {
        setLoader(false);
      }
    };

    fetchUserData();
  }, [shouldReload, shouldReloadSingleFile]);

  const handleNext = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }

    if (activeStep === 0) {
      handleLeadCapture(activeStep);
    }
    if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 2) {
      formDataUpdate();
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 4) {
      formSubmission();

      //  formUpdate(activeStep);
    }
    if (activeStep === 5) {
      //  formUpdate(activeStep);
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <StepZero
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
            handleNext={handleNext}
            loader={loader}
            toggle={toggle}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <StepOne
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
            handleNext={handleNext}
            loader={loader}
            toggle={toggle}
          />
        );
      case 2:
        return (
          <div className="container mt-5">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-8">
                <form>
                  <div className="signature_form" id="regForm">
                    <div
                      className="d-flex justify-content-center align-items-center py-2"
                      style={{
                        backgroundColor: "#0097B2",
                        borderEndStartRadius: "10px",
                        borderEndEndRadius: "10px",
                      }}
                    >
                      <span
                        className=""
                        style={{
                          color: "white",
                          fontWeight: "900",
                          fontSize: "32px",
                        }}
                      >
                        {toggle ? "Acuerdo del Cliente" : "Client Agreement"}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: "30px 13px 10px",
                        height: "80vh",
                        overflowY: "scroll",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "18px",
                          textAlign: "justify",
                        }}
                      >
                        <p style={{ marginBottom: "15px" }}>
                          {toggle
                            ? `Nos complace que haya decidido utilizar nuestro servicio en línea seguro y rentable para presentar a tiempo su
                        información de titularidad beneficiaria ("BOI") a la Red de Ejecución de Crímenes Financieros ("FinCEN").
                        FinCENfast proporciona tanto las presentaciones iniciales de BOI para las empresas que reportan como las presentaciones actualizadas cuando
                        cualquier BOI de su empresa o cualquiera de sus "propietarios beneficiarios" haya cambiado, lo que desencadena una actualización requerida
                        presentación dentro de los 30 días posteriores a dicho cambio. Lea estos Términos de uso ("Términos") cuidadosamente antes de utilizar nuestros
                        servicios, ya que rigen el uso de nuestro sitio web www.fincenfast.com y sus servicios disponibles.`
                            : `We are glad that you decided to use our safe and cost-effective online service to timely file your
                        beneficial ownership information (“BOI”) with the Financial Crimes Enforcement Network (“FinCEN”).
                        FinCENfast provides both initial BOI filings for reporting companies as well as updated filings when any of
                        the BOI of your company or any of its “beneficial owners” has changed, triggering a required updated
                        filing within 30 days of such change. Please read these Terms of Use ("Terms") carefully before using our
                        services, as they govern your use of our website www.fincenfast.com and its available services.`}
                        </p>

                        <p style={{ marginBottom: "15px" }}>
                          {toggle
                            ? `Al hacer clic en el botón de “`
                            : `By clicking on the “`}
                          <strong>
                            {toggle ? "Get Started" : "Get Started"}
                          </strong>
                          {toggle
                            ? `” a continuación y pagar electrónicamente nuestra tarifa de presentación electrónica de $150.00, tendrá acceso a nuestro calendario en línea para programar una fecha y hora para una videollamada por Zoom con uno de nuestros expertos en BOI, quien lo guiará a través del proceso de presentación electrónica en el sitio web de fincen.gov.`
                            : `” button below, and electronically paying our $150.00 e-filing fee, you will have access to our online calendar to schedule a date and time for a Zoom video call with one of our BOI experts, who will guide you through the e-filing process on the `}
                          <a
                            href="https://fincen.gov"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {toggle ? "fincen.gov" : "fincen.gov"}
                          </a>
                          {toggle ? ` sitio web.` : ` website.`}
                        </p>
                        <p style={{ marginBottom: "15px" }}>
                          {toggle
                            ? `Debe tener acceso a una computadora con una pantalla lo suficientemente grande como para acomodar cómodamente tanto la pantalla de video de Zoom como un "navegador web" como Google Chrome, Microsoft Edge, Apple Safari, Internet Explorer, etc. Con la asistencia de nuestro experto de FinCenSafe que estará en la videollamada con usted durante todo el proceso de presentación, lo ayudaremos a iniciar sesión en el sitio web de fincen.gov y a presentar la información de titularidad beneficiaria requerida sobre su empresa, cada titular beneficiario, y si corresponde, los "Solicitantes de la Empresa".`
                            : `You must have access to a computer with a computer screen large enough to comfortably accommodate both the Zoom video screen and a “website browser” such as Google Chrome, Microsoft Edge, Apple Safari, Internet Explorer, etc. With assistance from our FinCenSafe expert who will be on the video call with you throughout the filing process, we will assist you in logging onto the fincen.gov website and filing the required beneficial ownership information on your company, each beneficial owner, and if applicable, the “Company Applicants.”`}
                        </p>
                        <p style={{ marginBottom: "15px" }}>
                          {toggle
                            ? `Una pantalla de "teléfono inteligente" no es lo suficientemente grande como para completar de manera eficiente su Informe de Información de Titularidad Beneficiaria. Asegúrese de tener acceso a una computadora con una pantalla de tamaño completo.`
                            : `A “smart phone” screen is not large enough to efficiently complete your Beneficial Ownership Information Report. Please be sure that you have access to a computer with a full-size screen.`}
                        </p>
                        <p style={{ marginBottom: "15px" }}>
                          {toggle
                            ? `Debe tener acceso a una computadora con una pantalla lo suficientemente grande como para acomodar cómodamente tanto la pantalla de video de Zoom como un "navegador web" como Google Chrome, Microsoft Edge, Apple Safari, Internet Explorer, etc. Con la asistencia de nuestro experto de FinCenSafe que estará en la videollamada con usted durante todo el proceso de presentación, lo ayudaremos a iniciar sesión en el sitio web de fincen.gov y a presentar la información de titularidad beneficiaria requerida sobre su empresa, cada titular beneficiario, y si corresponde, los "Solicitantes de la Empresa".`
                            : `If you don not already have the “Zoom” application on your computer, you must also have downloaded and installed it on your computer by the time of your scheduled Zoom video call with our FinCen expert.  Click on the following link for instructions on downloading Zoom:`}
                          <span>
                            <a
                              className="fw-bold text-center ps-2"
                              href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0060928."
                              style={{
                                wordBreak: "break-all",
                              }}
                            >
                              https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0060928.
                            </a>
                          </span>
                        </p>
                        <p style={{ marginBottom: "15px" }}>
                          {toggle
                            ? `También debe tener toda la información de titularidad beneficiaria de su empresa, cada titular beneficiario de su empresa y, si corresponde, los "Solicitantes de la Empresa" disponibles al alcance de su mano en el momento de la videollamada programada por Zoom con nuestro experto de fincensafe.com. Al hacer clic en el siguiente enlace, puede descargar y hacer copias apropiadas de una versión en PDF de nuestra HOJA DE TRABAJO DE INFORMACIÓN DE TITULARIDAD BENEFICIARIA DE FINCEN. Si su empresa tiene más de un (1) titular beneficiario, deberá completar hojas de trabajo separadas solo para la BOI del TITULAR BENEFICIARIO para cada titular beneficiario.`
                            : `You must also have all of the beneficial ownership information on your company, each beneficial owner of your company, and if applicable, the “Company Applicants” readily available at your fingertips at the time of the scheduled Zoom video call with our fincensafe.com expert. By clicking on the following link, you can download and make appropriate copies of a PDF version of our a INFORMATION WORKSHEET. If your company has more than one (1) beneficial owner, you will need to fill out separate worksheets for just the BENEFICIAL OWNER BOI for each beneficial owner.`}
                        </p>
                        <p style={{ marginBottom: "15px" }}>
                          <p>
                            <a
                              href="https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide.v1.1-FINAL.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ wordBreak: "break-all" }}
                            >
                              https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide.v1.1-FINAL.pdf
                            </a>
                          </p>
                        </p>
                        <p style={{ marginBottom: "15px" }}>
                          {toggle
                            ? `Una vez que haya hecho clic en el botón "`
                            : `Once you have clicked on the “`}
                          <strong>
                            {toggle ? "Get Started" : "Get Started"}
                          </strong>
                          {toggle
                            ? `" y haya completado el pago de nuestra tarifa de presentación electrónica de $150.00, también acepta lo siguiente:`
                            : `” button and completed payment of our $150.00 e-filing fee, you also agree to the following:`}
                        </p>

                        <ul
                          className="pad"
                          style={{ backgroundColor: `rgba(0, 151, 178, 0.15)` }}
                        >
                          <div className="p-4">
                            <li className="signature_list_item">
                              {toggle
                                ? `Ha tenido la oportunidad de acceder a la Guía de Cumplimiento para Pequeñas Entidades de FinCEN (la "Guía"), que le instamos a revisar y que explica qué entidades son "empresas informantes" bajo la Ley de Transparencia Corporativa y qué individuos cumplirían con los criterios como "propietarios beneficiarios" de dicha empresa informante. Además, si su empresa se formó en o después del 1 de enero de 2024, la Guía explica quién es un "Solicitante de la Empresa" y qué información debe presentarse con respecto a dichos Solicitantes de la Empresa.`
                                : `You have had an opportunity to access the FinCEN Small Entity Compliance Guide (the "Guide"), which we urge you to review and which explains which entities are "reporting companies" under the Corporate Transparency Act and which individuals would meet the criteria as a "beneficial owner" of such reporting company. Further, if your company was formed on or after January 1, 2024, the Guide explains who is a "Company Applicant," and what information must be filed with regard to such Company Applicant(s).`}
                            </li>
                            <li
                              className="signature_list_item"
                              style={{ marginBottom: "15px" }}
                            >
                              {toggle
                                ? `Debe haber recopilado antes de su videollamada programada por Zoom y tener a mano la siguiente "Información de Titularidad Beneficiaria":`
                                : `You must have collected before your scheduled Zoom video call and have at your fingertips the following "Beneficial Ownership Information":`}
                              <ul style={{ marginBottom: "10px" }}>
                                <li style={{ margin: "10px 0px" }}>
                                  <strong>
                                    {toggle ? "Su empresa:" : "Your Company:"}
                                  </strong>
                                </li>
                                <ul className="marf">
                                  <li>
                                    {toggle
                                      ? "Nombre completo y cualquier nombre comercial (dba) de su empresa;"
                                      : "Full name and any trade names (dba's) of your company;"}
                                  </li>
                                  <li>
                                    {toggle
                                      ? "Dirección física actual del lugar principal de negocios (o sede en EE. UU.) de su empresa;"
                                      : "Current street address of principal place of business (or U.S. headquarters) of your company;"}
                                  </li>
                                  <li>
                                    {toggle
                                      ? "Jurisdicción estatal de formación de su empresa;"
                                      : "State jurisdiction of formation of your company;"}
                                  </li>
                                  <li>
                                    {toggle
                                      ? "Fecha de formación de su empresa;"
                                      : "Date of formation of your company;"}
                                  </li>
                                  <li>
                                    {toggle
                                      ? "Número de Identificación Fiscal de su empresa."
                                      : "Taxpayer Identification Number for your company."}
                                  </li>
                                </ul>
                              </ul>
                              <ul className="marf">
                                <li>
                                  <strong>
                                    {toggle
                                      ? "Cada Propietario Beneficiario"
                                      : "Each Beneficial Owner"}
                                    :
                                  </strong>
                                  {toggle
                                    ? " Un “Propietario Beneficiario” es una persona que, directa o indirectamente, ejerce “control sustancial” sobre una empresa informante, o que posee o controla al menos el 25% de los intereses de propiedad de la empresa informante."
                                    : " A “Beneficial Owner” is an individual who, directly or indirectly, exercises “substantial control” over a reporting company, or who owns or controls at least 25% of the ownership interests of the reporting company."}
                                  <div>
                                    {toggle
                                      ? "Vaya al siguiente enlace y a la página 16 de la Guía de Cumplimiento de Pequeñas Empresas de FinCEN para la definición de un propietario beneficiario y la amplia definición de “control sustancial”:"
                                      : "Go to the following link and to Page 16 of the FinCEN Small Company Compliance Guide for the definition of a beneficial owner and the broad definition of “substantial control”:"}
                                    <p>
                                      <a
                                        href="https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide.v1.1-FINAL.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ wordBreak: "break-all" }}
                                      >
                                        https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide.v1.1-FINAL.pdf
                                      </a>
                                    </p>
                                  </div>
                                  <div>
                                    {toggle
                                      ? "En la fecha y hora programadas de su videollamada de Zoom con nuestro experto de FinCEN, para cada “Propietario Beneficiario” debe tener a su alcance lo siguiente:"
                                      : "At the scheduled date and time of your Zoom video call with our FinCEN expert, for each “Beneficial Owner” you must have at your fingertips the following:"}
                                  </div>
                                </li>
                                <ul className="marf">
                                  <li>
                                    {toggle
                                      ? "El nombre completo de la persona;"
                                      : "The individual's full name;"}
                                  </li>
                                  <li>
                                    {toggle
                                      ? "La fecha de nacimiento de la persona;"
                                      : "The individual's date of birth;"}
                                  </li>
                                  <li>
                                    {toggle
                                      ? "La dirección residencial de la persona;"
                                      : "The individual's residential address;"}
                                  </li>
                                  <li>
                                    {toggle
                                      ? "Una copia en PDF de uno de los siguientes: (1) la parte frontal de la licencia de conducir estatal válida de la persona, (2) tarjeta de identificación emitida por un estado, gobierno local o tribu, (3) páginas con la foto del pasaporte válido de los Estados Unidos o pasaporte extranjero de la persona."
                                      : "A PDF copy of one of the following: (1) the front of the individual's valid state driver’s license, (2) ID card issued by a state, local government or tribe, (3) picture pages of the individual's valid United States or foreign passport."}
                                  </li>
                                </ul>
                              </ul>
                              <ul className="marf">
                                <li>
                                  <strong>
                                    {toggle
                                      ? "Solicitantes de la Empresa:"
                                      : "Company Applicants:"}
                                  </strong>
                                  <br />
                                  {toggle ? (
                                    <>
                                      Si su empresa fue formada antes del{" "}
                                      <strong>1 de enero de 2024</strong>,
                                      entonces{" "}
                                      <strong>no tiene la obligación</strong> de
                                      presentar la "Información de Propiedad
                                      Beneficiaria" para la persona o personas
                                      responsables de la presentación estatal
                                      que creó su empresa. Pero si su empresa
                                      fue formada el 1 de enero de 2024 o
                                      después, consulte las Páginas 34 y 35 de
                                      la Guía de Cumplimiento de FinCEN para
                                      obtener ayuda en la identificación del
                                      solicitante o solicitantes de la empresa.
                                      En la mayoría de los casos, será un
                                      representante de la empresa (un
                                      propietario o empleado de la empresa) o el
                                      abogado de su empresa encargado de la
                                      tarea de presentar los documentos ante el
                                      Secretario de Estado en el estado donde se
                                      formó su empresa, y posiblemente también
                                      un paralegal que lo hizo bajo la dirección
                                      del abogado de su empresa. En ese caso,
                                      tanto el abogado como el paralegal serían
                                      considerados "Solicitantes de la Empresa"
                                      y se debe proporcionar la información de
                                      propiedad beneficiaria de ambos.
                                    </>
                                  ) : (
                                    <>
                                      If your company was formed prior to{" "}
                                      <strong>January 1, 2024</strong>, then you
                                      have <strong>no obligation</strong> to
                                      file "Beneficial Ownership Information"
                                      for the individual or individuals who were
                                      responsible for the state filing that
                                      created your company. But if your company
                                      was formed on or after January 1, 2024,
                                      please see Pages 34 and 35 of the FinCEN
                                      Compliance Guide for help in determining
                                      the identity of the company applicant or
                                      applicants. In most cases, it will be
                                      either a company representative (an owner
                                      or employee of the company) or your
                                      company’s lawyer tasked with the job of
                                      filing with the Secretary of State in the
                                      state where your company was formed, and
                                      possibly a paralegal as well, who did so
                                      under the direction of your company’s
                                      lawyer. In that case both the lawyer and
                                      the paralegal would be considered "Company
                                      Applicants" and beneficial ownership
                                      information must be provided on both.
                                    </>
                                  )}
                                  <div>
                                    {toggle ? (
                                      <>
                                        Si su empresa fue formada el{" "}
                                        <strong>1 de enero de 2024</strong> o
                                        después, debe proporcionar la siguiente
                                        información para los solicitantes de la
                                        empresa:
                                      </>
                                    ) : (
                                      <>
                                        If your company was formed on or after{" "}
                                        <strong>January 1, 2024</strong>, you
                                        must provide the following information
                                        for the Company Applicant(s):
                                      </>
                                    )}
                                  </div>
                                </li>
                                <ul className="marf">
                                  {toggle ? (
                                    <>
                                      <li>El nombre completo del individuo:</li>
                                      <li>
                                        La fecha de nacimiento del individuo;
                                      </li>
                                      <li>
                                        La dirección residencial del individuo;
                                      </li>
                                      <li>
                                        Una copia en PDF de uno de los
                                        siguientes:
                                        <ul>
                                          <li>
                                            (1) el anverso de la licencia de
                                            conducir válida del estado del
                                            individuo,
                                          </li>
                                          <li>
                                            (2) una tarjeta de identificación
                                            emitida por un estado, gobierno
                                            local o tribu,
                                          </li>
                                          <li>
                                            (3) las páginas con foto del
                                            pasaporte válido de Estados Unidos o
                                            extranjero del individuo.
                                          </li>
                                        </ul>
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      <li>The individual’s full name:</li>
                                      <li>The individual’s date of birth;</li>
                                      <li>
                                        The individual’s residential address;
                                      </li>
                                      <li>
                                        A PDF copy of one of the following:
                                        <ul>
                                          <li>
                                            (1) the front of the individual’s
                                            valid state driver’s license,
                                          </li>
                                          <li>
                                            (2) ID card issued by a state, local
                                            government or tribe,
                                          </li>
                                          <li>
                                            (3) picture pages of the
                                            individual’s valid United States or
                                            foreign passport.
                                          </li>
                                        </ul>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </ul>
                            </li>
                          </div>
                        </ul>

                        <div className="text-center fw-bold my-3">
                          Your Agreement
                        </div>
                        <p>
                          By your checking the following box, you also agree:
                        </p>
                        <ul
                          className="pad"
                          style={{ backgroundColor: `rgba(0, 151, 178, 0.15)` }}
                        >
                          <li className="signature_list_item">
                            {toggle ? (
                              <>
                                Para proporcionarnos información completa y
                                precisa sobre la “propiedad beneficiaria” de su
                                empresa, cada Propietario Beneficiario y, si
                                corresponde, los Solicitantes de la Empresa;
                              </>
                            ) : (
                              <>
                                To provide us with complete and accurate
                                “beneficial ownership information” about your
                                company, each Beneficial Owner, and if
                                applicable, the Company Applicant(s);
                              </>
                            )}
                          </li>
                          <li className="signature_list_item">
                            {toggle ? (
                              <>
                                Para completar toda la “información de propiedad
                                beneficiaria” dentro de los noventa (90) días
                                siguientes a la compra de nuestro servicio de
                                carga. Si no puede hacerlo, cualquier
                                información incompleta será eliminada de
                                nuestros servidores y necesitará volver a
                                comprar nuestro servicio y empezar de nuevo.
                              </>
                            ) : (
                              <>
                                To complete all “beneficial ownership
                                information” within ninety (90) days of your
                                purchase of our upload service. If you are
                                unable to do so, any incomplete information will
                                be deleted from our servers and you will need to
                                repurchase our service and start over.
                              </>
                            )}
                          </li>
                          <li className="signature_list_item">
                            {toggle ? (
                              <>
                                Si descubre <strong>cualquier error</strong> en
                                la información beneficiaria que proporcionó
                                originalmente a FinCENsafe.com, o si hay{" "}
                                <strong>cambios</strong> en la información de
                                propiedad beneficiaria sobre su empresa o los
                                propietarios beneficiarios de su empresa,
                                comprende que debe corregir dichos errores de
                                inmediato dentro de los 30 días con una
                                presentación electrónica actualizada con FinCEN.
                              </>
                            ) : (
                              <>
                                If you discover <strong>any errors</strong> in
                                the beneficial information that you originally
                                provided to FinCENsafe.com, or if there are{" "}
                                <strong>any changes</strong> in the beneficial
                                ownership information about either your company
                                or the beneficial owners of your company, you
                                understand that you are required to immediately
                                correct such errors within 30 days with an
                                updated e-filing with FinCEN.
                              </>
                            )}
                          </li>
                          <li className="signature_list_item">
                            {toggle ? (
                              <>
                                Usted acepta que la relación contractual entre
                                FinCENsafe y su empresa creada por el presente,
                                está regida por lo anterior y complementada por
                                los Términos y Condiciones (“Términos”) de
                                FinCENsafe, los cuales están accesibles para
                                usted en el botón “Términos y Condiciones” a
                                continuación.
                              </>
                            ) : (
                              <>
                                You agree that the contractual relationship
                                between FinCENsafe and your company created
                                hereby, is governed by the foregoing and
                                supplemented by FinCENsafe’s Terms and
                                Conditions (“Terms”) which are accessible by you
                                on the “Terms and Conditions” button below.
                              </>
                            )}
                          </li>
                        </ul>
                      </div>

                      <form className="mb-4" style={{ padding: "0px" }}>
                        {/* <p style={{fontSize : "16px" , color : 'orangered', marginBottom: 10}}>*To print on the agreement, kindly provide the information below.</p> */}
                        <div className="row mb-2">
                          <div className="form-group col-md-6">
                            <label
                              for="inputEmail4"
                              style={{ fontWeight: 600 }}
                            >
                              {toggle ? "Nombre" : "Name"}
                            </label>

                            <p>
                              <input
                                value={signatureName}
                                onChange={handleNameChange}
                                placeholder="Enter your legal name"
                                className={`${
                                  errors.signatureName ? "border-danger" : ""
                                }`}
                                name="signature_print_name"
                              />
                            </p>
                          </div>
                          <div className="form-group col-md-6">
                            <label
                              for="inputEmail4"
                              style={{ fontWeight: 600 }}
                            >
                              {toggle ? "Correo Electrónico" : "Email"}
                            </label>
                            <p>
                              <input
                                placeholder="e.g. youremail@website.com"
                                value={formData.signature_email}
                                className={`${
                                  errors.signature_email ? "border-danger" : ""
                                }`}
                                onChange={handleInputChange}
                                name="signature_email"
                              />
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label
                              for="inputPassword4"
                              style={{ fontWeight: 600 }}
                            >
                              {toggle ? "Nombre de la Empresa" : "Company Name"}
                            </label>

                            <p>
                              <input
                                placeholder="Your legal business name"
                                value={formData.signature_company_name}
                                className={`${
                                  errors.signature_company_name
                                    ? "border-danger"
                                    : ""
                                }`}
                                onChange={handleInputChange}
                                name="signature_company_name"
                              />
                            </p>
                          </div>
                          <div className="form-group col-md-6">
                            <label
                              for="inputEmail4"
                              style={{ fontWeight: 600 }}
                            >
                              {toggle ? "Título" : "Title"}
                            </label>

                            <p>
                              <input
                                placeholder="e.g. manager"
                                value={formData.signature_title}
                                className={`${
                                  errors.signature_title ? "border-danger" : ""
                                }`}
                                onChange={handleInputChange}
                                name="signature_title"
                              />
                            </p>
                          </div>
                        </div>
                      </form>

                      <div>
                        <div className="form-options d-flex gap-3">
                          <div
                            className={
                              selectedOption == "autoGenerated"
                                ? "singature_tabs"
                                : ""
                            }
                            style={{ cursor: "pointer", fontWeight: "600" }}
                            onClick={() => handleOptionChange("autoGenerated")}
                          >
                            {toggle ? "Firma Automática" : "Auto Signature"}
                          </div>
                          <div
                            className={
                              selectedOption == "manualSignature"
                                ? "singature_tabs"
                                : ""
                            }
                            style={{ cursor: "pointer", fontWeight: "600" }}
                            onClick={() =>
                              handleOptionChange("manualSignature")
                            }
                          >
                            {toggle ? "Dibujar Firma" : "Draw Signature"}
                          </div>
                        </div>

                        {selectedOption === "autoGenerated" && (
                          <AutoGeneratedUI />
                        )}
                        {selectedOption === "manualSignature" && (
                          <div className="mt-4">
                            <span
                              style={{ fontSize: "22px", fontWeight: "600" }}
                            >
                              {toggle
                                ? "Firmas Autorizadas"
                                : "Authorized Signatures"}
                            </span>
                            <div
                              className="d-flex justify-content-center"
                              style={{
                                marginTop: "4px",
                                marginBottom: "15px",
                                flexDirection: "column-reverse",
                                alignItems: "end",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  border: "2px solid rgba(13, 6, 6, 0.18)",
                                  width: "100%",
                                  height: "170px",
                                  gap: "10px",
                                  justifyContent: "center",
                                }}
                              >
                                <SignatureCanvas
                                  penColor="black"
                                  canvasProps={{ className: "sigCanvas" }}
                                  onEnd={handleSignatureEnd}
                                  ref={sigRef}
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                              <span
                                className="d-flex mb-1"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => handleClearCanvasSignature(e)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  fill="red"
                                  className="bi bi-x-square-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        )}
                        {selectedOption === "uploadPhoto" && <UploadPhotoUI />}
                        <div>
                          <p className="confirmation-check">
                            <input
                              style={{ fontSize: 20, border: "1px solid gray" }}
                              checked={formData.confirmationAggrement}
                              class={` form-check-input me-1 mt-1 ${
                                errors.confirmationAggrement
                                  ? "border-danger"
                                  : ""
                              }`}
                              type="checkbox"
                              name="confirmationAggrement"
                              onChange={handleInputChange}
                            />
                            <span
                              style={{
                                fontSize: "17px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {" "}
                              {toggle
                                ? `Estoy de acuerdo con los${" "}`
                                : `I agree to the${" "}`}
                              <a
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color: "#1188F7",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal_step_19"
                              >
                                {toggle
                                  ? "Términos y condiciones"
                                  : "Terms and conditions"}
                              </a>
                            </span>
                          </p>
                          {errors.confirmationAggrement && (
                            <div
                              className="text-danger"
                              style={{ fontSize: "18px", fontWeight: 600 }}
                            >
                              {errors.confirmationAggrement}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          padding: "0px 0px 10px 0px",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 25,
                        }}
                      >
                        <Button
                          variant="contained"
                          disabled={loader}
                          style={{
                            padding: "10px 60px", // Adjust padding as needed
                            backgroundColor: "#FF5C36", // Change the background color as needed
                          }}
                          onClick={handleNext}
                        >
                          {toggle ? "Empezar" : "Get Started"}{" "}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className="modal fade"
                id="exampleModal_step_19"
                style={{
                  display: "none",
                  // padding: "0px 40px 20px 40px",
                }}
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div
                      class="modal-header text-white"
                      style={{ backgroundColor: "#0097B2 " }}
                    >
                      <h5
                        class="modal-title"
                        style={{ color: "white", marginLeft: 15 }}
                      >
                        <i
                          class="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>{" "}
                        FinCENsafe TERMS AND CONDITIONS
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <TermsAndConditionContent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <StepThree
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
            handleNext={handleNext}
            loader={loader}
          />
        );
      case 4:
        return (
          <div class="container mt-5">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-8">
                <form id="regForm">
                  <h4 id="register">
                    {toggle
                      ? "HOJA DE INFORMACIÓN SOBRE LA PROPIEDAD BENEFICIOSA DE FINCEN"
                      : "FINCEN BENEFICIAL OWNERSHIP INFORMATION WORKSHEET"}
                  </h4>

                  <CompanyBoi
                    formData={formData}
                    toggle={toggle}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleNext={handleNext}
                    loader={loader}
                  />

                  <div
                    className="tab content-tab py-3 mt-0"
                    style={{
                      marginTop: 18,
                      marginBottom: "45",
                      backgroundColor: "#FF5C362E",
                    }}
                  >
                    <h5
                      style={{
                        fontSize: 16,
                        fontWeight: "600",
                        color: "rgb(255 92 54)",
                      }}
                      class="text-center font-weight-500 pb-2 fs-4"
                    >
                      {toggle
                        ? "PROPIETARIA BENEFICIAL BOI:"
                        : "BENEFICIAL OWNER BOI:"}
                    </h5>

                    {applicants.map((applicant, index) => (
                      <BeneficialOwnerComponent
                        key={index}
                        toggle={toggle}
                        index={index}
                        applicant={applicant}
                        handleCancel={handleCancel}
                        handleNameChangeBeneficialOwners={
                          handleNameChangeBeneficialOwners
                        }
                        renderFileInputOrURL={renderFileInputOrURL}
                        handleChangeSSN={handleChangeSSN}
                      />
                    ))}
                    {errorMessage && (
                      <p style={{ color: "red", marginTop: 5 }}>
                        {toggle ? "Mensaje de error" : errorMessage}
                      </p>
                    )}

                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        className="beneficial-owner-btn"
                        type="button"
                        onClick={handleAddMore}
                      >
                        {toggle
                          ? "Haga clic aquí para agregar otro propietario beneficiario"
                          : "Click here to add another beneficial owner"}
                      </button>
                    </div>
                  </div>

                  <div
                    className="tab content-tab py-3 mt-0"
                    style={{
                      marginTop: 18,
                      marginBottom: "45",
                      backgroundColor: "#BBBBCB45",
                    }}
                  >
                    <h5
                      style={{ fontSize: 19, fontWeight: "600" }}
                      class="text-center font-weight-600 mb-3 py-3"
                    >
                      {toggle ? (
                        <>
                          INFORMACIÓN SOBRE LA PROPIEDAD BENEFICIOSA DEL
                          SOLICITANTE DE LA EMPRESA<br></br>(para entidades
                          formadas después del 31 de diciembre de 2023)
                        </>
                      ) : (
                        <>
                          COMPANY APPLICANT’S BOI <br></br>(for entities formed
                          after December 31, 2023)
                        </>
                      )}
                    </h5>

                    <CompanyApplicantBoi
                      formData={formData}
                      errors={errors}
                      toggle={toggle}
                      handleInputChange={handleInputChange}
                      handleFileChangeApplicant={handleFileChangeApplicant}
                      handleViewSingleFile={handleViewSingleFile}
                      fileLoader={fileLoader}
                      ssn={ssn}
                      handleChange={handleChange}
                      userData={userData}
                      errorApplicantMessage
                    />

                    <p className="confirmation-check">
                      <input
                        style={{ fontSize: 18, border: "1px solid gray" }}
                        checked={formData.isCheckedConfirmation}
                        class={` form-check-input me-1 mt-1 ${
                          errors.isCheckedConfirmation ? "border-danger" : ""
                        }`}
                        type="checkbox"
                        name="isCheckedConfirmation"
                        onChange={handleInputChange}
                      />
                      {toggle
                        ? "Represento, garantizo, pacto y acepto que la información sobre la propiedad beneficiaria proporcionada por mí es veraz y exacta y autorizo a FinCENsafe a presentar electrónicamente dicha información sobre la propiedad beneficiaria ante la Red de Aplicación de Delitos Financieros en mi nombre y en nombre de la Empresa."
                        : "I represent, warrant, covenant and agree that the beneficial ownership information provided by me is truthful and accurate and I authorize FinCENsafe to e-file such beneficial ownership information with the Financial Crimes Enforcement Network on my behalf and on behalf of the Company."}
                    </p>

                    {errors.isCheckedConfirmation && (
                      <div
                        className="text-danger"
                        style={{ fontSize: "18px", fontWeight: 600 }}
                      >
                        {errors.isCheckedConfirmation}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      padding: "0px 0px 14px 0px",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 25,
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={loader || fileLoader} // Disable based on loader state
                      sx={{
                        fontSize: 20,
                        fontWeight: 600,
                        padding: {
                          xs: "10px 80px",
                          md: "10px 250px",
                          lg: "10px 250px",
                        },

                        backgroundColor: "#FF5C36", // Change the background color as needed
                      }}
                      onClick={handleNext}
                    >
                      {toggle ? "Enviar" : "Submit"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      case 5:
        return <StepFive />;

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 10,
        padding: "0px 0px 14px 0px",
        backgroundImage:
          " linear-gradient(direction, color-stop1, color-stop2)",
      }}
    >
      {loader ? <LoadingScreen /> : getStepContent()}
    </Box>
  );
};

export default Form;
